// import logo from './logo.png';
import './App.css';
// import LottieAnimation from './LottieAnimation/LottieAnimation'
// import ZohoForm from './ZohoForm/ZohoForm';
import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route,Link } from 'react-router-dom';
import Draft from './OverruleDraft/OverruleDraft';
import Header from './Header/Header';
import ScrollCountUp from "./ScrollCountUp/ScrollCountUp";
// import PricingComponent from './Pricing/PricingComponent';
import ButtonGroup from './ButtonGroup/ButtonGroup';
import AiDraftingService from './AiDraftingService/AiDraftingService';
import LegalResearchService from './LegalResearchService/LegalResearchService';
import BenefitsSection from './BenefitsSection/BenefitsSection';
import FAQSection from './FAQSection/FAQSection';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import ContactUs from './ContactUs/ContactUs';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import RefundPolicy from './RefundPolicy/RefundPolicy';
import CancellationPolicy from './CancellationPolicy/CancellationPolicy';

const Footer = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <footer className="footer">
      <div className="footer-links">
      <a
          href="/terms-and-conditions"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
          Terms & Conditions
        </a>
        <a
          href="/refund-policy"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
          Refund Policy
        </a>
        <a
          href="/cancellation-policy"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
          Cancellation Policy
        </a>
        <a
          href="/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
          Privacy Policy
        </a>
        <span onClick={openModal} className="footer-link clickable">
          Contact Us
        </span>
      </div>
      <p className="footer-text">
        © {new Date().getFullYear()} Overrule.ai. All rights reserved.
      </p>
      <ContactUs isOpen={isModalOpen} onClose={closeModal}>
        <div className="contact-modal-content">
          <p><strong>Email:</strong> info@overrule.ai</p>
          <p><strong>Phone:</strong> +91-9911116888</p>
          <p><strong>Address:</strong> Ground Floor, FIEE Complex A-19, Block-A, Phase-II, Okhla Industrial Estate, South Delhi, Delhi - 110020</p>
        </div>
      </ContactUs>
    </footer>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<>
          <div className='video-background'>
          <div class="background-overlay"></div>
            <Header />
            <video autoPlay muted loop>
              <source src="https://build.themovation.com//wp-content//uploads//2023//08//mixkit-long-hallway-in-data-center-23219-medium.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="container">

              {/* <LottieAnimation /> */}
              <div className="hero-section text-flex-center">
                <h1>Unleash the Future of </h1>
                <h1>Law with AI</h1>
              </div>
              <ButtonGroup />
            </div>
          </div>

          <div className="gradient-section-full-width" id="services">
            <div className="stats-container">
              <div className="stats-grid">
                <div className="stats-item">
                  <ScrollCountUp endValue={350} label="Cases Drafted" />
                </div>
                <div className="stats-item">
                  <ScrollCountUp endValue={534} label="Law Firms" />
                </div>
                <div className="stats-item">
                  <ScrollCountUp endValue={3481} label="Trusted Lawyers" />
                </div>
              </div>
            </div>
            <div className='parallax-section'>
            <div className="services-container pt-50">
              <AiDraftingService />
            </div>
            <div className="services-container pt-50 pb-50">
              <LegalResearchService />
            </div>
            </div>            
          </div>
          <div className="gradient-section" id="benefits">
            <div className="text-flex-left">
              <h1 className='font-primary-h1 font-600 mb-0'>Revolutionizing legal drafting with cutting-edge.</h1>
              <p className=' font-primary font-500'>Unlock the Power of AI in Legal Drafting and Research</p>
            </div>
            <BenefitsSection/>
          </div>
          {/* <div className="gradient-section" id="subscription">
            <div className="text-flex-center">
              <h1 className='font-primary-h1 font-400'>Choose a plan that work best for you and your firm</h1>
              <PricingComponent />
            </div>
          </div> */}
          <div className="gradient-section" id="faq">
            <div className="text-flex-left">
              <h1 className='font-primary-h1 font-600 mb-0'>Frequently Asked Questions</h1>
            </div>
            <FAQSection/>
          </div>
          {/* <div className="gradient-section" id="contactus">
            <div className="text-flex-left">
              <h1 className='font-primary-h1 font-600 mb-0'>Contact</h1>
            </div>
            <ContactUs/>
          </div> */}
           <Footer /> {/* Adding the Footer */}
        </>} />
        <Route path="/createdraft" element={<Draft />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/cancellation-policy" element={<CancellationPolicy />} />
      </Routes>
    </Router>


  );
}

export default App;

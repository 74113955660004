import React, { useState } from 'react';
import './FAQSection.css'; // Assuming you place styles in this file

const FAQSection = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  const faqs = [
    {
      question: "What is Overrule?",
      answer:
        "Overrule is an AI-enabled large language model created to draft legal (litigation and non-litigation) documents efficiently and to provide access to extensive and exclusive research materials for all legal purposes. Overrule’s services help users with quick and easy information retrieval, content summarization, and document creation."
    },
    {
      question: "Who can benefit from Overrule?",
      answer: "Legal professionals, law firms, and anyone involved in legal documentation."
    },
    {
      question: "What kind of legal documents can I draft using Overrule?",
      answer: "Overrule can assist in drafting contracts, agreements, legal notices, and more."
    },
    {
      question: "Are Overrule’s services legitimate and statutorily compliant?",
      answer: "Yes, Overrule’s services comply with statutory regulations and provide legitimate solutions."
    },
    {
      question: "What are the services and pricing options provided by Overrule?",
      answer: "Overrule offers various subscription plans based on your document drafting needs."
    }
  ];

  return (
    <section className="faq-section">
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              <h3>{faq.question}</h3>
              <span>{openFAQ === index ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
              <circle cx="30" cy="30" r="29" stroke="white" strokeWidth="2" />
              <path d="M29.1274 29.127H18V30.8724H29.1274L30.8729 30.8724H42V29.127H30.8729H29.1274Z" fill="white" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
              <circle cx="30" cy="30" r="30" fill="#F6F9FD" />
              <path d="M30.8729 18H29.1274V25.1274C29.1274 27.3365 27.3365 29.1274 25.1274 29.1274H18V30.8729H25.1274C27.3365 30.8729 29.1274 32.6637 29.1274 34.8729V42H30.8729V34.8729C30.8729 32.6637 32.6637 30.8729 34.8729 30.8729H42V29.1274H34.8729C32.6637 29.1274 30.8729 27.3365 30.8729 25.1274V18Z" fill="#010101" />
            </svg>
          )}</span>
            </div>
            {openFAQ === index && <p className="faq-answer">{faq.answer}</p>}
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQSection;


import './CancellationPolicy.css'; // Assuming you place styles in this file

const CancellationPolicy = () => {
    return (
        <>
            <section className="cancellation-policy">
                <h2 className='cancellation-policy-title'>Cancellation Policy for Overrule.AI</h2>
                <div></div>
                <div>Effective Date: 26th January, 2025</div>
                <div></div>
                <div>1. Overview</div>
                <div>This Cancellation Policy outlines how users can cancel their subscriptions or services with Overrule.AI. By using our services, you agree to this policy.</div>
                <div>2. Cancellation Process</div>
                <div>Users may cancel their subscription at any time by providing written notice to info@overrule.ai. The cancellation notice should include:<div><ul>
                    <li>User's full name and account details.</li>
                    <li>Reason for cancellation (optional).</li>
                </ul>
                </div></div>
                <div>3 Effective Date of Cancellation</div>
                <div>
                    <div>
                        <ul>
                            <li>
                                Cancellations will take effect at the end of the current billing cycle, allowing users to continue accessing services until that date.
                            </li>
                            <li>
                                Users will not be charged for subsequent billing cycles after cancellation is processed.
                            </li>
                        </ul>
                    </div>
                </div>
                <div> 4. Refunds upon Cancellation</div>
                <div>Users who cancel their subscription may be eligible for a refund as outlined in our Refund Policy if they meet the specified criteria.
                </div>
                <div>5. Termination by Quikbyte</div>
                <div>Quikbyte AI Private Limited reserves the right to terminate access to services for reasons including but not limited to:
                    <div>
                        <ul>
                            <li>
                                Breach of Terms of Use.
                            </li>
                            <li>
                                Non-payment of fees.
                            </li>
                            <li>
                                Engagement in prohibited actions as detailed in our Terms of Use.
                            </li>
                        </ul>
                    </div>
                </div>
                <div>6. Changes to Policy</div>
                <div>Quikbyte AI Private Limited may update this Cancellation Policy from time to time, with any significant changes communicated via email or through notifications on our platform.</div>
                <div></div>
                <div>By using Overrule.AI, you acknowledge that you have read and understood these policies and agree to abide by them. For any questions regarding these policies, please contact us at info@overrule.ai.
                </div>
            </section>
        </>
    );
};

export default CancellationPolicy;
import React from 'react';
import './ButtonGroup.css'; // Import the CSS file

const ButtonGroup = () => {
  return (
    <div className="button-group-container">
      <div className="button-group">
        <button className="btn btn-explore">Explore More</button>
        <button className="btn btn-try">Try for free</button>
      </div>
    </div>
  );
};

export default ButtonGroup;

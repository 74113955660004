import React from 'react';
import './BenefitsSection.css'; // Assuming you place styles in this file

const BenefitsSection = () => {
  const benefits = [
    {
      id: "01",
      title: "Time Efficiency",
      description: "AI powered research and draft bring down the cost and is time efficient."
    },
    {
      id: "02",
      title: "Precision & Accuracy",
      description: "Drafting and research designed to increase efficiency and bring out accuracy."
    },
    {
      id: "03",
      title: "Compliance Assurance",
      description: "Automated and accurate research and drafting enables to promote efficiency and optimisation."
    },
    {
      id: "04",
      title: "Customization",
      description: "Effective research and efficient drafting of legal documents by artificial intelligence made to provides a competitive edge."
    }
  ];

  return (
    <section className="benefits-section">
      <div className="benefits-grid">
        {benefits.map(benefit => (
          <div key={benefit.id} className="benefit-card">
            <h2 className="benefit-id">{benefit.id}</h2>
            <h3 className="benefit-title">{benefit.title}</h3>
            <p className="benefit-description">{benefit.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default BenefitsSection;


import './TermsAndConditions.css'; // Assuming you place styles in this file

const TermsAndConditions = () => {
    return (
        <>
            <section className="terms-and-conditions">
                <h2 className='terms-and-conditions-title'>Terms of Use for Overrule.AI</h2>
                <div></div>
                <div>1. Agreement to Terms</div>
                <div>These Terms of Use ("Terms") constitute a binding agreement between you ("User") and Quikbyte AI Private Limited ("Quikbyte"), the provider of the Overrule.AI platform ("Overrule"). By accessing or using the platform and services available at www.overrule.ai, you acknowledge that you have read, understood, and agreed to these Terms. If you do not agree, you must discontinue the use of the platform and services immediately.</div>
                <div>2. Description of Services</div>
                <div>Overrule.AI is an artificial intelligence-powered platform providing legal research, legal drafting, and translation services designed for lawyers, legal professionals, law students, scholars, legal academicians, and judges. The services are accessible through both a subscription model and a pay-as-you-go model.</div>
                <div>3. Authorized Use</div>
                <div></div>
                <div>3.1. Permitted Use</div>
                <div>You are granted a limited, non-exclusive, non-transferable, and revocable license to access and use the platform solely for authorized business purposes.</div>
                <div>3.2. Prohibited Actions</div>
                <div>You shall not:<div><ul>
                    <li>Copy, reverse engineer, decompile, disassemble, or attempt to derive the source code of the platform.</li>
                    <li>Share login credentials or allow unauthorized access.</li>
                    <li>Use the platform for any unlawful, illegal, or unethical purposes.</li>
                    <li>Upload data that violates applicable laws or third-party rights.</li>
                </ul>
                </div></div>
                <div>3.3. Misuse and Penalties</div>
                <div>
                    In case of misuse or violation of these Terms, Quikbyte reserves the right to:
                    <div>
                        <ul>
                            <li>
                                Suspend or terminate your access to the platform.
                            </li>
                            <li>
                                Impose penalties, including additional fees for unauthorized usage.
                            </li>
                            <li>
                                Pursue legal remedies, if applicable.
                            </li>
                        </ul>
                    </div>
                </div>
                <div> 4. User Obligations</div>
                <div></div>
                <div>4.1. Account Security</div>
                    <div><ul><li>Users are responsible for maintaining the confidentiality of their login credentials.</li>
                        <li>Any activity conducted through an account will be deemed authorized by the account holder.</li></ul>
                    </div>
               
                <div>4.2. Compliance</div>
                <div> Users must comply with all applicable laws, regulations, and internal company policies while using the platform.</div>
                <div> 4.3. Incident Reporting</div>
                <div>Users must promptly report any unauthorized use or security breach affecting their account.</div>
                <div>5. Payment Terms</div>
                <div>
                    <ul>
                        <li>
                            Users exceeding notified usage limits (e.g., number of documents or users) will be charged extra as per applicable rates.</li>
                        <li>Payments must be made within the specified timelines to avoid late payment charges or service suspension.</li>
                    </ul>
                </div>
                <div>6. Intellectual Property</div>
                <div> All intellectual property, including software, design, methodologies, and features of the Overrule.AI platform, remains the exclusive property of Quikbyte AI Private Limited.</div>
                <div>7. Privacy and Data Protection</div>
                <div></div>
                <div>7.1. Ownership of Data</div>
                <div>All data input and output by the User ("Customer Data") remains the sole property of the User or their organization.</div>
                <div>7.2. Data Processing</div>
                <div>Quikbyte acts as a data processor and processes Customer Data solely as per the instructions of the User, in compliance with applicable data protection laws.</div>
                <div>7.3. Data Security</div>
                <div>Quikbyte employs industry-standard security measures to protect Customer Data from unauthorized access, loss, or misuse. However, Users are responsible for ensuring their use of the platform complies with applicable privacy laws.</div>
                <div>7.4. Restrictions on Data Use</div>
                <div>
                    Customer Data will not be:
                    <div>
                        <ul>
                            <li>
                                Used for training or enhancing AI models.
                            </li>
                            <li>
                                Shared with third parties unless explicitly authorized.
                            </li>
                            <li>
                                Retained after termination of the subscription.
                            </li>
                        </ul>
                    </div>
                </div>
                <div>8. Limitation of Liability</div>
                <div>
                    <ul>
                        <li>Quikbyte is not liable for indirect, consequential, or punitive damages, including data loss or loss of business opportunities.</li>
                        <li>Quikbyte's total liability is limited to the subscription fees paid for the relevant license period in which any issue occurred.</li>
                    </ul>
                </div>
                <div>9. Termination</div>
                <div></div>
                <div>9.1. Termination by User</div>
                <div>The User may terminate their subscription by providing written notice to info@overrule.ai.</div>
                <div>9.2. Termination by Quikbyte</div>
                <div>
                    Quikbyte reserves the right to terminate access for:
                    <div>
                        <ul>
                            <li>
                                Breach of these Terms.
                            </li>
                            <li>
                                Non-payment of fees.
                            </li>
                            <li>
                                Engagement in prohibited actions.
                            </li>
                        </ul>
                    </div>

                </div>
                <div>9.3. Effect of Termination</div>
                <div>Upon termination, access to the platform will be revoked, and the User must settle any outstanding payments.</div>
                <div>10. Governing Law and Dispute Resolution</div>
                <div>These Terms are governed by the laws of India. Any disputes will be subject to the exclusive jurisdiction of the courts in New Delhi. Parties will attempt amicable resolution before seeking legal recourse.</div>
                <div>11. Disclaimers</div>
                <div>The Services do not constitute and shall neither be construed nor interpreted or relied upon as legal advice, counsel, opinion, or recommendations. You agree not to use the Services as a substitute for legal, commercial, or contractual advice by qualified professionals. Quikbyte disclaims any warranties or assurances of the accuracy of the Services.</div>
                <div>12. Updates to Terms</div>
                <div>Quikbyte may revise these Terms periodically. Updated Terms will be posted on the website. Continued use of the platform constitutes acceptance of the revised Terms.</div>
                <div>13. Contact Information</div>
                <div>For support or inquiries, contact us at: info@overrule.ai.</div>
            </section>
        </>
    );
};

export default TermsAndConditions;
import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import "./ScrollCountUp.css"; // External CSS for styling

const ScrollCountUp = ({ endValue, label }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger only once when the element comes into view
    threshold: 0.5,    // The percentage of the element that must be visible to trigger
  });

  return (
    <div className="countup-container" ref={ref}>
      {inView && (
        <div className="countup-number">
          <CountUp end={endValue} duration={3} />
          <span className="font-blue">+</span>
        </div>
      )}
      <p className="countup-label">{label}</p>
    </div>
  );
};

export default ScrollCountUp;

import React from 'react';
import './LegalResearchService.css';

const LegalResearchService = () => {
  return (
    <section className="legal-research-service">
  <div className="lrs-content-wrapper">   
    <div className="lrs-text-content">
      <h4>Legal Research</h4>
      <h1>Empowering Legal Precision with AI Drafting</h1>
      <p>
        At overrule, we leverage cutting-edge artificial intelligence (AI) technology to revolutionize the world of legal drafting.
      </p>
      <div className="lrs-buttons">
        <button className="lrs-learn-more-btn">Notify me</button>
        <button className="lrs-free-trial-btn">Notify me</button>
      </div>
    </div>
    <div className="lrs-video-placeholder">
      <img src="images/legal_research_service.png" alt="Video Placeholder" />
      <div className="lrs-play-button">
        <i className="fa fa-play-circle"></i>
      </div>
    </div>
  </div>
</section>

  );
};

export default LegalResearchService;

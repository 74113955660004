import React from 'react';
import './AiDraftingService.css';

const AiDraftingService = () => {
  return (
    <section className="ai-drafting-service">
  <div className="content-wrapper">
    <div className="video-placeholder">
      <img src="images/ai_drafting_service.png" alt="Video Placeholder" />
      <div className="play-button">
        <i className="fa fa-play-circle"></i>
      </div>
    </div>

    <div className="text-content">
      <h4>AI Legal Drafting</h4>
      <h1>Empowering Legal Precision with AI Drafting</h1>
      <p>
        At overrule, we leverage cutting-edge artificial intelligence (AI)
        technology to revolutionize the world of legal drafting.
      </p>
      <div className="buttons">
        <button className="learn-more-btn">Learn More</button>
        <button className="free-trial-btn">Free Trial</button>
      </div>
    </div>
  </div>
</section>

  );
};

export default AiDraftingService;


import './PrivacyPolicy.css'; // Assuming you place styles in this file

const PrivacyPolicy = () => {
    return (
        <>
            <section className="privacy-policy">
                <h2 className='privacy-policy-title'>Privacy Policy for Overrule.AI</h2>
                <div></div>
                <div>Effective Date: 26th January, 2025</div>
                <div></div>
                <div>1. Overview</div>
                <div>At Overrule.AI, a brand of Quikbyte AI Private Limited, we are committed to protecting your privacy and ensuring a secure online experience. This Privacy Policy outlines the types of information we collect, how we use and disclose that information, and the steps we take to safeguard your personal data. By using our services, you agree to the terms and conditions outlined in this Privacy Policy. This Privacy Policy is incorporated into and governed by our <a target='_blank' href="/terms-and-conditions">Terms of Use</a></div>
                <div></div>
                <div>This policy applies to all websites and services under the Overrule.AI brand, which provides AI-powered solutions for legal research, drafting, and translation services. Our platform, <a href='https://www.overrule.ai/'>https://www.overrule.ai/</a>, is designed to deliver a seamless and efficient experience for legal professionals and organizations.</div>
                <div>2 INFORMATION WE COLLECT</div>
                <div></div>
                <div>2.1 PERSONALLY IDENTIFIABLE INFORMATION (PII)</div>
                <div>
                When you register on our platform or use our services, we may ask for personally identifiable information, including but not limited to:
                    <div>
                        <ul>
                            <li>
                            Full name
                            </li>
                            <li>
                            Email address
                            </li>
                            <li>
                            Phone number
                            </li>
                            <li>
                            Professional title and company name (if applicable)
                            </li>
                            <li>
                            Address (business or personal)
                            </li>
                            <li>
                            Other relevant details related to your profile
                            </li>
                        </ul>
                    </div>
                    <div>
                    This information is necessary to create your account, facilitate transactions, and provide you with our AI-powered legal services.
                    </div>
                </div>
                <div>2.2 NON-IDENTIFYING INFORMATION</div>
                <div>
                    <div>We may also collect non-identifying information, such as:</div>
                    <ul>
                    <li>IP address</li>
                    <li>Browser type and version</li>
                    <li>Pages visited on our website</li>
                    <li>Time spent on the website</li>
                    <li>Other browsing-related data</li>
                    </ul>
                    <div></div>
                    <div>
                    This information helps us improve our services, analyze user behavior, and optimize the overall user experience.
                    </div>
                </div>
                <div>2.3. SOCIAL MEDIA DATA</div>
                <div>If you register with Overrule.AI through a third-party social media platform (such as Facebook, LinkedIn, Twitter, Instagram etc.), we may collect certain personal information shared through that platform, including your name, email address, and profile data. The specific information we collect depends on your privacy settings on that platform.</div>
                <div>2.4. LOG DATA</div>
                <div>We automatically collect log data when you visit our website, including:</div>
                <div></div>
                <div>
                    <ul>
                        <li>IP address</li>
                        <li>Browser type</li>    
                        <li>Referring URL</li>    
                        <li>Pages accessed on our website</li>    
                        <li>Time and date of your visit</li>                        
                    </ul>
                    <div>We use this data to improve our website’s functionality and user experience. Log data is processed through Google Analytics, and we encourage you to review Google’s privacy policy to understand how they handle this data.</div>
                </div>
                <div>3. COOKIES</div>
                <div>We use cookies to enhance your user experience. Cookies are small files placed on your device that help us customize content and track your preferences. You can manage your cookie settings through your browser, but disabling cookies may affect certain functionalities of our platform.</div>
                <div>4. USE OF YOUR INFORMATION</div>
                <div>
                    The information we collect from you is used in the following ways:
                    <ul>
                        <li>To provide, maintain, and improve our services</li>
                        <li>To personalize your experience on our platform</li>
                        <li>To communicate with you about your account, services, and promotions</li>
                        <li>To analyze usage patterns and improve our offerings</li>
                        <li>To send newsletters, updates, and marketing communications (you can opt out at any time)</li>
                    </ul>
                </div>
                <div>5. USE OF YOUR INFORMATION</div>
                <div>
                    <div>We understand the importance of your privacy and take all necessary steps to protect your data from unauthorized access. We will not share, sell, or rent your personally identifiable information to third parties for marketing purposes.</div>
                    <div>However, we may disclose your personal information in the following situations:</div>                   
                </div>
                <div>5.1 Legal Requirements</div>
                <div>
                    <div>We may disclose your information if required by law, such as in response to a subpoena or other legal process.</div>                   
                </div>
                <div>5.2 Protection of Rights</div>
                <div>
                    <div>We may disclose your information to protect the rights, property, or safety of Overrule.AI, our users, or others.</div>                   
                </div>
                <div>6. OUR DISCLOSURE OF YOUR INFORMATION</div>
                <div>
                    <div>In certain situations, we may be required to disclose your personal information:</div>                   
                </div>
                <div>6.1 Legal Requirements</div>
                <div>
                    <div>We may disclose your information in response to a valid request by law enforcement or other government agencies.</div>                   
                </div>
                <div>6.2 Business Transactions</div>
                <div>
                    <div>In the event of a business transaction (such as a merger or acquisition), your personal information may be transferred as part of the transaction.</div>                   
                </div>
                <div>7. THIRD-PARTY LINKS</div>
                <div>
                Our website may contain links to other websites or services operated by third parties. We are not responsible for the privacy practices or content of these external sites. We recommend reviewing the privacy policies of any third-party websites you visit.                  
                </div>
                <div>8. SECURITY</div>
                <div>
                We employ a variety of security measures to safeguard your personal data, including encryption, firewalls, and secure socket layer (SSL) technology. However, no method of data transmission over the Internet is completely secure, and we cannot guarantee the absolute security of your data.                  
                </div>
                <div>9. USE OF SOCIAL MEDIA FEATURES</div>
                <div>
                Our website may include social media features, such as the "Like" or "Share" buttons, which are operated by third-party companies. These features may collect your IP address, track the pages you visit, and set cookies to enable functionality. Your interactions with these features are governed by the privacy policy of the third-party company.                  
                </div>
                <div>10. PRIVACY POLICY UPDATES</div>
                <div>
                We may update this Privacy Policy from time to time. Any changes to the policy will be posted on our website, and if the changes are significant, we will notify you via email. By continuing to use our services after these changes take effect, you accept the revised Privacy Policy.                  
                </div>
                <div>11. CONTACT US</div>
                <div>
                    <div>
                    If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                    </div>
                    <div>Overrule.AI  </div>
                    <div>A brand of Ouikbyte AI Private Limited  </div>
                    <div>Email: [info@overrule.ai] </div>
                    <div>Website: [www.overrule.ai]</div>                  
                </div>
                <div>12. YOUR RIGHTS</div>
                <div>
                You have the right to access, update, or delete your personal information. To exercise these rights, please contact us at [info@overrule.ai], and we will guide you through the process. We will process your requests in accordance with applicable laws and within a reasonable timeframe.
                </div>
            </section>
        </>
    );
};

export default PrivacyPolicy;

import './RefundPolicy.css'; // Assuming you place styles in this file

const RefundPolicy = () => {
    return (
        <>
            <section className="refund-policy">
                <h2 className='refund-policy-title'>Refund Policy for Overrule.AI</h2>
                <div></div>
                <div>1. Overview</div>
                <div>This Refund Policy outlines the terms under which users can request a refund for services provided by Overrule.AI, a brand of Quikbyte AI Private Limited. By using our services, you agree to this policy.</div>
                <div>2. Eligibility For Refunds</div>
                <div>Refunds may be requested under the following conditions:<div><ul>
                    <li>Service Not Delivered: If the service was not delivered as promised or was significantly different from what was described.</li>
                    <li>Technical Issues: If you encounter persistent technical issues that prevent you from using the service despite attempts to resolve them.</li>
                </ul>
                </div></div>
                <div>3 Request Process</div>
                <div>
                    Users must submit a refund request via email to info@overrule.ai within 30 days of the transaction date. The request should include:
                    <div>
                        <ul>
                            <li>
                                User's full name and account details.
                            </li>
                            <li>
                                Description of the issue and reason for the refund request.
                            </li>
                            <li>
                                Any relevant documentation or evidence supporting the claim.
                            </li>
                        </ul>
                    </div>
                </div>
                <div> 4. Refund Amount</div>
                <div><ul>
                    <li>If a refund is approved, users will receive a refund equal to the amount paid for the specific service that was not delivered or was unsatisfactory.</li>
                    <li>Refunds will not exceed the total amount charged for the service in question and will be processed back to the original payment method used during purchase.</li></ul>
                </div>
                <div>5. Review and Approval</div>
                <div>Quikbyte AI Private Limited will review all refund requests within 14 business days. If approved, refunds will be processed back to the original payment method used during purchase.</div>
                <div>6. Non-Refundable Services</div>
                <div>The following services are non-refundable:</div>
                <div></div>
                <div>
                    <ul>
                        <li>
                            Subscription fees for ongoing services after the subscription period has commenced.
                        </li>
                        <li>Payments made for additional features or services that have been accessed</li>
                    </ul>
                </div>
                <div>7. Changes to Policy</div>
                <div> Quikbyte AI Private Limited reserves the right to modify this Refund Policy at any time. Users will be notified of significant changes via email or through notifications on our platform.</div>
            </section>
        </>
    );
};

export default RefundPolicy;